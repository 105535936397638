.search-container {
    max-width: 800px; /* Limit the maximum width of the container */
    margin: 0 auto; /* Center the container horizontally */
    padding: 30px; /* Add padding to the container */
    background-color:rgba(255, 255, 255, 0.8);
    background-color: rgba(230, 230, 255, 0.8);
    border: 1px solid #ddd; /* Add a border around the container */
    border-radius: 8px; /* Add border radius for rounded corners */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); /* Add box shadow for depth */
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 20px;
    position: absolute;
    bottom: -90px;
    flex-direction: column;
    font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif ;
  }

  .search-container2 {
    max-width: 800px; /* Limit the maximum width of the container */
    margin: 0 auto; /* Center the container horizontally */
    padding: 30px; /* Add padding to the container */
    background-color:rgba(255, 255, 255, 0.8);
    background-color: rgba(230, 230, 255, 0.0);
    /* border: 1px solid #ddd; */
    border-radius: 8px; /* Add border radius for rounded corners */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); 
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 20px;
    flex-direction: column;
    font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif ;
  }

  .search-container h2{
    color: rgba(0, 0, 255, 0.5);
    text-align: center;
  }

  .search-container1{
    display: flex;
    flex-wrap:wrap ;
    justify-content: center;
    align-items: center;
    gap: 20px
    ;
  }
  
  .search-container1 label {
    display: block; /* Display each label on a new line */
    margin-bottom: 10px; /* Add spacing between labels */
    gap:10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .search-container1 select, .search-container1 button {
    /* margin-bottom: 10px; */
    /* background-color: red; */
    cursor: pointer;
    font-size: 1rem;
    padding: 5px;
    outline: none;
    border:1px solid rgba(0, 0, 255, 0.5);
    /* color: rgba(0, 0, 255, 0.5); */
    /* overflow-y: scroll; */
  }

  .search-container1 label select option{
    font-size: 0.7rem;
  }
  
 .search-container1 button {
    background-color: #007bff;
    color: #fff; /* Set button text color */
    border: none; /* Remove button border */
    padding: 10px 20px; /* Add padding to button */
    border-radius: 4px; /* Add border radius for rounded corners */
    /* cursor: pointer; */
  }
  
  .search-container1 button:hover {
    background-color: #0056b3; /* Change background color on hover */
  }
  

  @media screen and (max-width:834px) {
    .search-container, .search-container2{
      width: 90%;     
    }
  }

  @media screen and (max-width:428px) {
    .search-container1{
      gap:10px;
    }
  }

  @media screen and (max-width:375px) {
    .search-container2{
      width:100%;
      /* background-color: red; */
    }
  }

  @media screen and (max-width:360px) {
    .search-container1 label select option{
      font-size: 0.6rem;
    }
    .search-container, .search-container2{
      padding: 10px;
      width: 95%;
    }
  }