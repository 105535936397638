.GalleryWrap{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    height: 500px;
    padding: 100px;
    position: relative;
    flex-direction: column;
    background-color: rgb(240,240,255);
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.GalleryWrap h1{
    color: rgba(0, 0, 255, 0.5);
    margin-bottom: 20px;
}

.GalleryWrap2{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 70%;
}

.Gallery{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
}

.GalleryPhotoWrap{
    width: 30%;
    height: 100%;
}

.GalleryPhotoWrap img{
    width: 100%;
    height: 100%;
}

.Increament{
    position: absolute;
    top: 50%;
    right: 10px;
    font-size: 2rem;
    color: rgba(0, 0, 255, 0.5);
    cursor: pointer;
    transition: 350ms;
    transform: scale(1);
}

.Increament:hover{
    transform: scale(1.2);
}

.Decreament{
    position: absolute;
    top:50%;
    left:10px ;
    font-size: 2rem;
    color: rgba(0, 0, 255, 0.5);
    cursor: pointer;
    transition: 350ms;
    transform: scale(1);
}

.Decreament:hover{
    transform: scale(1.2);
}


@media screen and (max-width:834px){
    .GalleryWrap{
        padding: 100px 80px;
    }
}


@media screen and (max-width:428px){
   
    .Gallery{
        flex-direction: column;
       
    }
    .GalleryWrap{
        height: 100vh;
        justify-content: space-between;
        /* margin-bottom: 50px; */
        padding: 20px 50px;
    }
    .GalleryPhotoWrap{
        width: 80%;
        margin-bottom: 20px;
    }
    .GalleryWrap h1{
        margin-bottom: 0px;
        margin-top: 10px;
    }
}

@media screen and (max-width:320px) {
    .GalleryPhotoWrap{
        /* margin-bottom: 10px; */
        margin-top: 0px;
    }

    .GalleryWrap{
        height: 120vh;
    }
}