/* Styles for Our Services Page */

/* Our Services section */
.our-services {
    
    padding: 80px 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* background-color: rgb(240, 240, 255); */
    background-color: rgb(230, 230, 255);
    border-bottom: 1px solid rgba(0, 0, 255, 0.2); 
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    animation: 5s colorchange infinite;
  }

  @keyframes colorchange {
    0% { background-color: rgb(230, 230, 255); }
    25% { background-color: rgb(220, 230, 255); }
    /* 50% { background-color: rgb(150, 220, 255); } */
  }
  

  .our-services h1{
    margin: 20px;
    font-size: 4rem;
    text-align: center;
    color: rgba(0, 0, 255, 0.5);
  }
  
  /* Service cards container */
  .service-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  /* Individual service card */
  .service-card {
    width: calc(50% - 20px);
    margin: 10px;
    display: flex;
    align-items: center;
    /* justify-content: center; */
    /* background-color: #f0f8ff; */
    /* background-color: rgb(240, 240, 255); */
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    overflow: hidden;
    transition: box-shadow 0.3s ease;
    position: relative; /* Added for pseudo-element */
    padding: 20px;
  }
  
  /* Pseudo-element for hover effect */
  /* .service-card::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    opacity: 0;
    transition: opacity 0.3s ease;
  } */
  
  /* Hover effect on card */
  /* .service-card:hover::before {
    opacity: 1;
  } */
  
  /* Image inside service card */
  .service-card img {
    width: 40%;
    height: auto;
    object-fit: cover;
    transition: transform 0.3s ease; /* Added for image zoom effect */
    border-radius: 10px;
  }
  
  /* Zoom effect on image hover */
  .service-card:hover img {
    transform: scale(1.1);
  }
  
  /* Details section of the service card */
  .service-details {
    padding: 20px;
    /* position: relative; */
    /* z-index: 1; */
    /* opacity: 1; */
    transform: translateY(20px);
    transition: opacity 0.3s ease, transform 0.3s ease; /* Added for fade-in effect */
  }

  .service-details h3{
    color: rgba(0, 0, 255, 0.5);
  }
  
  /* Fade-in effect on details hover */
  .service-card:hover .service-details {
    opacity: 1;
    transform: translateY(0);
  }
  
  /* Mobile Responsiveness */
  @media screen and (max-width: 884px) {
    .service-card {
      width: calc(50% - 20px);
      flex-direction: column;
    }
    .service-card img{
        width: 80%;
    }
  }

  @media screen and (max-width: 768px) {
    .service-card {
      width: calc(100% - 20px);
      flex-direction: row;
    }
    .service-card img{
        width: 40%;
    }
  }
  
  @media screen and (max-width: 428px) {
    .service-card {
      width: calc(100% - 20px);
      flex-direction: column;
    }
    .service-card img{
        width: 80%;
    }
    .our-services{
        padding: 80px 5px;
    }
    .our-services h1{
        font-size: 2.5rem;
             
    }

  }