.OurProductCollectionForHomeWrap{
    width: 100%;
    height: auto;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

}

.OurProductCollectionForHome{
    width: 100%;
    height: 600px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    }

    .OurProductCollectionForHome img{
        width: 100%;
        height: 100%;
    }

.OurProductCollectionForHomeMask{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    flex-direction: column;
    gap:20px;
    background-color: rgba(0, 0, 0, 0.2);
    color: white;
    position: absolute;
}

.OurProductCollectionForHomeMask h1{
    font-size: 3.5rem;
    text-align: center;
}

.OurProductCollectionForHomeMask h2{
    text-align: center;
    padding: 50px;
    background-color: rgba(2, 2, 104, 0.5);
}

.OurProductCollectionForHomeMask button{
    padding: 15px 25px;
    font-size: 1.3rem;
    cursor: pointer;
    background: linear-gradient(to bottom, rgba(224, 224, 247, 1), rgba(0, 0, 255, 0.7));
    border-radius: 5px;
    border: none;
    color: white;
    transition: 350ms;
    transform: scale(1);
}

.OurProductCollectionForHomeMask button:hover{
    transform: scale(1.1);
}

@media screen and (max-width:820px) {
    .OurProductCollectionForHomeMask h1{
        font-size: 3rem;
       
    }
    .OurProductCollectionForHomeMask h2{
        /* padding: 20px; */
       
    }
}

@media screen and (max-width:375px) {
    .OurProductCollectionForHomeMask h1{
        font-size: 2.3rem;
       
    }
    .OurProductCollectionForHomeMask h2{
        font-size: 1.2rem;
       
    }
   
}