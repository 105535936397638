.OurServicesWrap {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    padding-top: 50px;
    padding-bottom: 50px;
    background-color: rgb(230, 230, 255);
}

.OurServices {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: auto;
    text-align: center;
}

.OurServicesHeading {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    color: rgba(0, 0, 255, 0.75);
}

.OurServicesHeading h1{
    font-size: 4rem;
}

.OurServicesBody {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
}

.OurService {
    display: flex;
    flex-direction: column;
    width: 30%;
    height: auto;
    margin:20px;
    justify-content: center;
    align-items: center;
    border-radius: 50px 0px 50px 0px;
    padding: 30px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    background-color: whitesmoke;
    transition: 350ms;
}

.OurService:hover{
    transform: scale(1.05);
}

.OurService h3 {
    /* font-size:1.7rem; */
    color: rgb(0, 0, 255,0.5);
}

.OurService p{
    color:rgb(0, 0, 140);
    /* font-size: 1.2rem; */
}

.OurServiceBodyIcon {
    /* color: rgba(0, 0, 255, 0.5); */
    color:rgb(0, 0, 140,0.5);
    font-size: 2rem; /* Adjusted icon size */
}

/* .ContactUsNowWrap {
    color: blue;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: underline;
    font-style: italic;
    cursor: pointer;
    transform: scale(1);
    transition: 350ms;
    animation: headerAnimate 5s infinite alternate;
}

.ContactUsNowWrap h1{
    font-size: 2rem;
}

.ContactUsNowWrap:hover {
    transform: scale(1.1);
}

.ContactUsNowWrap:active {
    transform: scale(0.9);
} */

@keyframes headerAnimate {
    0% { transform: scaleX(1.1); }
    25% { transform: scaleX(1.2); }
    50% { transform: scaleX(1.3); }
    75% { transform: scaleX(1.4); }
    100% { transform: scaleX(1.5); }
}

@keyframes headerAnimate2 {
    0% { transform: scaleY(1); }
    25% { transform: scaleY(1.1); }
    50% { transform: scaleY(1.15); }
    75% { transform: scaleY(1.2); }
    100% { transform: scaleY(1.25); }
}






@media screen and (max-width:1024px) {
    .OurService{
        width: 45%;
    }
}

@media screen and (max-width:768px) {
    .OurService{
        width: 90%;
    }

    .OurServicesHeading h1{
        font-size: 2.7rem;
    }

    .ContactUsNowWrap{
        animation: headerAnimate2 1s infinite alternate;
        padding: 5px;
    }
    .ContactUsNowWrap h1{
        font-size: 1.5rem;
    }
}

@media screen and (max-width:320px) {
    
}