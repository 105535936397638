/* BlogPage.css */

/* Global Styles */
.BlogsWrap {
    width: 100%;
    /* display: flex; */
    /* font-family: Arial, sans-serif; */
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    margin: 0;
    padding: 100px 100px;
    /* overflow-x: scroll; */
    background-color: rgba(230, 230, 255);
  }

  .BlogsWrap h1{
    text-align: center;
    color: rgba(0, 0, 255, 0.5);
    margin-bottom: 30px;
    font-size: 2.5rem;
  }
  
  /* Blog Page Styles */
  .blog-posts {
    /* display: grid; */
    display: flex;
    /* flex-direction: column; */
    width: 100%;
    height: auto;
    justify-content: center;
    align-items: center;
    /* height: 100vh; */
    /* overflow-x: scroll; */
    flex-wrap: wrap;
    /* grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)); */
    gap: 20px;
  }
  
  .blog-post {
    width: 100%;
    height: auto;
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    /* overflow: hidden;  */
    transition: all 0.3s ease; /* Add transition for smooth expand/collapse */
  }
  
  .blog-post img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 8px;
  }
  
  .post-info h2 {
    color: #007bff;
    font-size: 20px;
    margin-top: 10px;
    margin-bottom: 5px;
  }
  
  .post-info p {
    font-size: 14px;
    color: #666;
    margin-bottom: 5px;
  }
  
  .post-info p:last-child {
    margin-bottom: 0;
  }
  
.post-info button {
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 8px 16px;
    cursor: pointer;
  }
  
  .post-info button:hover {
    background-color: #0056b3;
  }
  
  /* Responsive Styles */
  @media (max-width: 428px) {
    .blog-posts {
      
    }
    .BlogsWrap{
      padding: 100px 20px;
    }
  }

  @media (max-width: 360px) {
    .BlogsWrap{
      padding: 80px 20px;
    }
  }
  
  