.AboutUsWrap{
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* background-color: rgb(240, 240, 255); */
    background-color: rgb(230, 230, 255);
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.AboutUsHeaderWrap{
    width:100%;
    display: flex;
    flex-direction: column;
    height: auto;
    padding-top: 120px;
    padding-bottom: 50px;
    background-image: url("../Images/team.jpg");
    background-size: cover;
    background-position: center;
    justify-content: center;
    align-items: center;
    position: relative;
    /* z-index: ; */
}

.AboutUsHeaderWrap::before{
  width: 100%;
  height: 100%;
  position: absolute;
  top:0;
  left: 0;
  content: "";
  background-color: rgba(255, 255, 255, 0.7);
  /* background-color: rgba(0, 0, 0, 0.7); */
  z-index: 0;
}

.AboutUsHeaderWrap h1{
    font-size: 4rem;
    /* color: purple; */
    color: rgba(0, 0, 255, 0.6);
    z-index: 1;
}


.about-us {
    /* padding: 50px; */
   
  }


  .WhoWeAreAndDoWrap{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: auto;
    /* padding: 100px; */
    flex-direction: column;
    gap:50px;
    /* background-color: red; */
  }

  

  .WhoWeAreAndDo{
    padding: 100px;
    display: flex;
    /* flex-direction: column; */
    width: 100%;
    height: auto;
    justify-content: center;
    align-items: center;
    gap:50px;
  }

  .WhoWeAreAndDoImgWrap{
    width: 50%;
    height: auto;
  }

  .WhoWeAreAndDoImgWrap img{
    width: 100%;
    height: auto;
  }

  .WhoWeAreTextWrap{
    width: 50%;

    /* background-color: red; */
  }

  .WhoWeAreTextWrap h1,.WhatWeDo h1{
    color: rgba(0, 0, 255, 0.5);
    font-size: 3rem;
  }

.WhatWeDo{
  padding: 100px 100px;
  width: 100%;
  background-color: rgba(240, 240, 255, 0.7);
  background-image: url("../Images/what\ we\ do\ img.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  /* Create a semi-transparent white gradient on top of the background image */
  background-image: linear-gradient(rgba(220, 220, 255, 0.9), rgba(220, 220, 255, 0.9)), url("../Images/what\ we\ do\ img.jpg");
  background-blend-mode: overlay; /* Blend the gradient with the background image */
  /* font-weight: 500; */

}
  .WhatWeDo p span{
    color: blue;
  }

/* Our Mission and ision Styles */

.OurMissionAndVision{
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;
  flex-direction: column;
  background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
  background-image: url("../Images/mission\ and\ ision\ 2.jpg");
  background-position: center;
  background-size: cover;
  padding: 100px;
}



/* .OurMissionAndVision::before{
  background-color: rgba(0, 0, 0, 0.7);
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

} */


.OurMission{
  width: 50%;
  height: auto;
  justify-content:center;
  align-items:center;
  display: flex;
  flex-direction: column;
  gap:10px;
  z-index: 1;
  /* background-color: red; */
  /* padding-top: 100px; */
  margin-right: 50%;
  
}

.OurVision{
  width: 50%;
  height: auto;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  gap:10px;
  /* padding-top: 100px; */
  z-index: 1;
  margin-left: 50%;
  
  /* background-color: red; */
}

.OurVision:nth-child(2){
  /* justify-content: flex-end; */
  /* align-items: flex-end; */
}

.OurMission h1{
  font-size: 3rem;
  color: rgba(255, 255, 255,0.9);
  text-align: center;
}

.OurVision h1{
  font-size: 3rem;
  color: rgba(255, 255, 255,0.9);
  text-align: center;
}


.OurMission p{
  font-size: 1rem;
  width: 100%;
  color: white;
  background-color: rgba(0, 0, 0, 0.8);
  padding: 50px;
  border-radius: 20px;
  text-align: center;
}

.OurVision P{
  font-size: 1rem;
  width: 100%;
  text-align: center;
  color: white;
  background-color: rgba(0, 0, 0, 0.8);
  padding: 50px;
  border-radius: 20px;
}



/* .OurMission{
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;
  flex-direction: column;
}
.OurMission h1{
  font-size: 3rem;
  color: rgba(0, 0, 255, 0.5);
}

.OurMissionBody{
  display: flex;
  width: 100%;
  height: auto;
  justify-content: center;
  align-items: center;
  gap: 50px;
}

.OurMissionImageWrap{
  width: 50%;
  height: auto;
}

.OurMissionImageWrap img{
  width: 100%;
  height: auto;
}

.OurMissionTextWrap{
  width: 50%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
 */

 
  
  .section {
    padding-top: 100px;
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* background-color: rgba(0, 0, 255, 0.295); */
  }

  .section h4{
    color: rgba(0, 0, 255, 0.8);
    text-align: center;
    font-size: 1.1rem;
  }

  .section h2{
    color: rgba(0, 0, 255, 0.5);
    text-align: center;
    font-size: 3rem;
  }

  .section p{
    text-align: center;
    padding: 0px 50px;
  }
  
  .team {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    /* background-color: red; */
    flex-wrap: wrap;
    width: 100%;
    padding: 0px 0px;
  }
  
  .team-member {
    /* width: calc(25% - 20px);  */
    width: 280px;
    margin:30px;
    position: relative;
    cursor: pointer;
  }
  
  .team-member img {
    width: 100%;
    height: auto;
    border-radius: 50%;
  }

  .team-member p{
    text-align: center;
    color: blue;
    font-style: italic;
  }
  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  
  .overlay.active {
    opacity: 1;
  }
  
  .overlay p {
    color: #fff;
    text-align: center;
    margin-bottom: 10px;
    font-size: 0.8rem;
  }


  .ContactUsNowWrap {
    margin: 20px; /* Add margin */
    text-align: center;
}

.ContactUsNowWrap h1 {
    cursor: pointer;
    font-size: 1.5rem; /* Adjust font size */
    color: rgba(0, 0, 255, 0.7); /* Change color */
    transition: 350ms;
    animation: animation1 5s infinite;
}

@keyframes animation1 {
    0%{transform: scaleX(1.1);}
    25%{transform: scaleX(1.2);}
    50%{transform: scaleX(1.3);}
    75%{transform: scaleX(1.4);}
    100%{transform: scaleX(1.5);}
}

@media screen and (max-width:884px) {
    .team-member{
        /* width: 27%; */
    }
    .WhoWeAreAndDoWrap{
      gap:0px;
    }
    .WhoWeAreAndDo{
      flex-direction: column;
      /* padding: 50px 100px; */
      padding-bottom: 0;
    }
    .WhoWeAreAndDoImgWrap{
      width: 100%;
    }
    .WhoWeAreAndDo img{
      width:100%;
    }
    .WhoWeAreTextWrap{
      width: 100%;
      /* background-color: red; */
    }
    .OurMission p, .OurVision p{
      width: 100%;
     
    }

    .OurMission, .OurVision{
      align-items: center;
      margin-left: 0%;
      margin-right: 0%;
      width: 100%;
      
    }
    .OurMission h1, .OurVision h1{
      margin-left: 0px;
      margin-right: 0px;
    }
}

@media screen and (max-width:834px) {
    .WhoWeAreAndDoWrap{
        flex-direction: column;
        padding: 50px;
    }
    .WhoWeAreAndDoImgWrap, .WhoWeAreAndDo{
        width: 100%;
        margin: 20px;
    }
}

@media screen and (max-width:768px){
  .team-member{
    /* width: 30%; */
}
}

@media screen and (max-width:428px) {
    .team-member{
        /* width: 60%; */
    }
    .section p{
    padding: 0px 15px;
    }
    .WhoWeAreAndDoWrap{
        padding: 0px;
        /* gap: 0px; */
    }
    .WhoWeAreAndDo{
      padding: 0px 50px;
      gap:0px;
    }
    .WhoWeAreAndDo h1{
      text-align: center;
    }
    .about-us{
        /* padding: 20px; */
    }
    .WhatWeDo{
      padding: 50px 50px;

    }
    .WhatWeDo h1{
      text-align: center;
    }
    .OurMissionAndVision{
      padding: 50px 10px;
    }
}

@media screen and (max-width:375px) {
    
    .WhoWeAreAndDo h1{
      font-size: 2rem;
    }
    .WhatWeDo h1{
      font-size: 2rem;
    }
    .OurMission p, .OurVision p{
      padding: 30px;
    }
    .team-member{
      width: 250px;
  }
}

@media screen and (max-width:320px) {
  .AboutUsHeaderWrap h1{
    font-size: 2.5rem;
  } 
  .OurMission h1, .OurVision h1{
    font-size: 2rem;
  }
  .team-member{
    /* width: 67%; */
}
  
}