.PropertiesWrap {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: auto;
    justify-content: space-between;
    align-items: center;
    padding: 100px 50px;
    background-color: rgb(240, 240, 255); /* Fallback background color */
    background-image: url("../Images/06532644919c1c-modern-beautifully-finished-home-semi-detached-duplexes-for-sale-ajah-lagos.jpeg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

    /* Create a semi-transparent white gradient on top of the background image */
    background-image: linear-gradient(rgba(240, 240, 255, 0.05), rgba(255, 255, 255, 0.05)), url("../Images/06532644919c1c-modern-beautifully-finished-home-semi-detached-duplexes-for-sale-ajah-lagos.jpeg");
    background-blend-mode: overlay; /* Blend the gradient with the background image */
}


.PropertiesWrap h1{
    font-size: 3rem;
    margin-bottom: 50px;
    color: rgba(0, 0, 255, 0.5);
    text-align: center;
}



.PropertiesContainer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 60px;
    padding-top:50px;
  }
  
  .PropertyCard {
    width: 300px;
    border: 1px solid #ccc;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    position: relative;
  }
  
  .carousel {
    position: relative;
  }

  .carousel-inner{
    width: 100%;
    height: 150px;
  }
  
  .carousel-inner img {
    width: 100%;
    height: 100%;
    display: none;
  }
  
  .carousel-inner img:first-child {
    display: block;
  }
  
  .prev,
  .next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    cursor: pointer;
    padding: 10px;
    /* z-index: 1; */
  }
  
  .prev {
    left: 0;
  }
  
  .next {
    right: 0;
  }
  
  .property-details {
    padding: 15px;
   
  }
  
  .property-details h3 {
    margin-top: 0;
  }
  
  .property-details p {
    margin: 5px 0;
  }

  .property-details p:nth-child(5) {
    margin: 5px 5px;
    color: red;
    position: absolute;
    top:0;
    right: 0;
    padding: 5px;
    background-color: white;
    color: rgba(0, 0, 255, 0.7);
    font-weight: 500;
    /* font-style: italic; */
    border-radius: 5px;
    font-size: 0.9rem;

  }
  
  .property-details button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 8px 12px;
    border-radius: 3px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .property-details button:hover {
    background-color: #0056b3;
  }


  .PropertySearchWrap{
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* background-color: red; */
  }

  .PropertySearchWrap h2{
    color: rgba(0, 0, 255, 0.5);
    text-align: center;
  }

  .SearchBarWrap{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40%;
    height: auto;
    margin: 20px;
    border: 1px solid rgba(0, 0, 255, 0.4);
    border-radius: 11px;
    background-color: rgba(255, 255, 255, 0.8);
  } 

  .SearchBarWrapHeroPage{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40%;
    height: auto;
    margin: 20px;
    border: 1px solid rgba(0, 0, 255, 0.3);
    border-radius: 11px;
    background-color: rgba(255, 255, 255, 0.9);
  }
  

  .SearchBarWrap input{
    width: 85%;
    padding: 10px;
    font-size: 1rem;
    outline: none;
    border: none;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 00);
    padding: 17px 20px ;
  }

  
  .SearchBarWrapHeroPage input{
    width: 85%;
    padding: 10px;
    font-size: 1rem;
    outline: none;
    border: none;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 00);
    padding: 17px 20px ;
  }


  .SearchBarWrap p{
    width: 15%;
    color: white;
    /* font-weight: bold; */
     /* background-color: #007bff; */
     background-color: rgba(0, 0, 255, 0.4);
     /* background-color: rgba(0, 0, 0, 0.6); */
    /* background: linear-gradient(to bottom, rgb(179, 179, 231,0.5), rgba(0, 0, 255, 0.7)); */
    border: none;
    border-radius: 0px 10px 10px 0px;
    cursor: pointer;
    transform: scale(1);
    transition: 350ms;
    padding: 17px 10px;
  }

  .SearchBarWrapHeroPage p{
    width: 15%;
    color: white;
    /* font-weight: bold; */
     /* background-color: #007bff; */
     /* background-color: rgba(0, 0, 255, 0.4); */
     background-color: rgba(0, 0, 0, 0.6);
    /* background: linear-gradient(to bottom, rgb(179, 179, 231,0.5), rgba(0, 0, 255, 0.7)); */
    border: none;
    border-radius: 0px 10px 10px 0px;
    cursor: pointer;
    transform: scale(1);
    transition: 350ms;
    padding: 17px 10px;
  }

  .SearchBarWrap p:hover{
    font-weight: bold;
  }

  .SearchBarWrap p:active{
    background-color: rgba(0, 0, 0, 0.7);
  }

  .SearchBarWrapHeroPage p:hover{
    font-weight: bold;
  }

  .SearchBarWrapHeroPage p:active{
    background-color: rgba(0, 0, 0, 0.9);
  }

 


  @media screen and (max-width:884px) {
    .SearchBarWrap, .SearchBarWrapHeroPage{
      width: 58%;
    }
  
    .PropertiesWrap{
      padding: 90px 20px;
    }
 
  }
  
  @media screen and (max-width: 768px) {
    .PropertiesContainer {
      /* flex-direction: column; */
      align-items: center;
    }
  
   
  }

  @media screen and (max-width:428px) {
    .SearchBarWrap, .SearchBarWrapHeroPage{
      width: 85%;
    }
    .SearchBarWrap input, .SearchBarWrapHeroPage input{
        width: 80%;
    }
    .SearchBarWrap p, .SearchBarWrapHeroPage p{
      width: 20%;
    }
    .PropertiesWrap{
      /* padding: 90px 0px; */
    }
    .PropertiesWrap h1{
      margin-bottom: 30px;
      font-size: 2.5rem;
    }
    .PropertiesContainer{
      padding-top: 30px;
    }
  }



  @media screen and (max-width: 360px) {
    .PropertiesWrap{
      padding: 90px 30px;    
    }
    .SearchBarWrap, .SearchBarWrapHeroPage{
      width: 90%;
    }
    .PropertiesWrap{
      padding: 90px 0px;
    }
    .PropertiesWrap h1{
      font-size: 2.2rem;
    }
    
  }

  @media screen and (max-width: 320px) {
    .SearchBarWrap, .SearchBarWrapHeroPage{
      width: 90%;
    }
    .SearchBarWrap input, .SearchBarWrapHeroPage input{
      width:75%;
    }
    .SearchBarWrap p, .SearchBarWrapHeroPage p{
      width: 25%;
    }
  }
  