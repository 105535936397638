.HeroWrap{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    position: relative;
    background-image: url("../Images/house5.jpg");
    background-position: center;
    background-size: contain;
    flex-direction: column;
    position: relative;
}
.Hero{
    display: flex;
    width: 100%;
    height: 620px;
    justify-content: center;
    align-items: center;
    /* background-color: lightgray; */
}

.Hero img{
    width: 100%;
    height: 100%;
}

.HeroMask{
    background-color: rgba(0, 0, 0, 0.1);
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.HeroMaskTextWrap{
    width: 90%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    
    /* background-color: rebeccapurple; */
}

.HeroMaskTextLeft{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30%;
    /* height: 30%; */
    flex-direction: column;
    /* background-color: rgba(0, 0, 0, 0.5); */
    border-radius: 10px;
    margin-top: 100px;
}

.HeroMaskTextLeft h1{
    font-size: 4rem;
    font-style: italic;
    color:rgba(255, 255, 255, 0.7);
    text-align: center;
    
}

.HeroMaskTextLeft h2{
    font-size: 1rem;
    color:rgba(255, 255, 255, 0.7);
    text-align: center;
}

.HeroMaskTextRight{
    display:flex ;
    justify-content: center;
    align-items: center;
    width: 50%;
    /* height: 30%; */
}

.HeroMaskTextRight p{
    font-size: 1.2rem;
    color: white;
    padding: 30px;
    background-color: rgba(0, 0, 0, 0.4);
    /* background-color: rgba(200, 200, 255, 0.5); */
    border-radius: 10px;
    font-style: italic;
    text-align: center;
}

@media screen and (max-width:834px) {
    .Hero{
        height: 900px;
    }
   .HeroMaskTextWrap{
    flex-direction: column;
    width: 100%;
   }
   
   .HeroMaskTextLeft{
    height:40%;
    width: 100%;
    text-align: center;
    align-items: center;
   }
   .HeroMaskTextRight{
    height: 40%;
    width: 80%;
   }

}

@media screen and (max-width:428px) {
    .Hero{
        height: 700px;
    }
    
    .HeroMaskTextLeft{
     height:40%;
     width: 100%;
     text-align: center;
     align-items: center;
    }

    .HeroMaskTextLeft h1{
        font-size: 6rem;
    }
    .HeroMaskTextLeft h2{
        font-size: 1.5rem;
        padding: 4px;
    }
    .HeroMaskTextRight{
        display: none;
     height: 40%;
     width: 80%;
    }

    .HeroMaskTextRight p{
        font-size: 1rem;
    }
 
 }

 @media screen and (max-width:360px) {
    .HeroMaskTextRight p{
        padding: 30px;
    }  
 }