/* CareerPage.css */

/* Global Styles */
.CareerWrap {
    font-family: Arial, sans-serif;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    margin: 0;
    padding: 0;
    background-color: rgb(240, 240, 255);
  }
  
  .container {
    max-width: 1200px;
    margin: 0 auto;
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 60px;
    padding-top:50px;
  }
  
  /* Hero Section */
  .hero {
    background-image: url('../Images/career\ img\ 1.jpeg');
    background-size: cover;
    background-position: center;
    color: #fff;
    text-align: center;
    padding: 100px 0;
  }
  
  .hero-content {
    max-width: 600px;
    margin: 0 auto;
  }
  
  .hero h1 {
    font-size: 48px;
    margin-bottom: 20px;
  }
  
  .hero p {
    font-size: 18px;
    margin-bottom: 30px;
  }
  
  .scroll-down-btn {
    background-color: #007bff;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    font-size: 18px;
    text-decoration: none;
  }
  
  .scroll-down-btn:hover {
    background-color: #0056b3;
  }
  
  /* About Section */
  .about-section {
    /* background-color: #f9f9f9; */
    padding: 80px 0;
  }

  .about-section h1{
    text-align: center;
    font-size: 2rem;
    margin-bottom: 30px;
    color: rgba(0, 0, 255, 0.5);
  }
  
  .CareerCard {
    display: flex;
    /* margin-bottom: 20px; */
    width: 40%;
    justify-content: center;
    align-items: center;
    gap:20px;
    border: none;
    box-shadow: none;
  }

  .CareerIcon{
    color: rgba(0, 0, 255, 0.5
    );
    font-size: 6rem;
  }
  

  
 
  
  .CareerCard h3 {
    color:rgba(0, 0, 255, 0.5);
    /* font-size: 1rem; */
    margin-bottom: 5px;
  }
  
  .CareerCard p {
    /* font-size: 16px; */
    /* line-height: 1.5; */
  }
  
  /* Background Image Section */
  .bg-image-section {
    background-image: url('../Images//career\ img\ 2.jpeg');
    background-size: cover;
    background-position: center;
    height: 300px; /* Adjust height as needed */
  }
  
  /* Open Positions Section */
  .open-positions {
    padding: 80px 0;
  }

  .open-positions h1{
    text-align: center;
    font-size:2rem;
    color: rgba(0, 0, 255, 0.5);
  }
  
  .open-positions .job {
    border-bottom: 1px solid rgba(0, 0, 255, 0.5);
    padding-bottom: 20px;
    /* margin-bottom: 20px; */
  }

  .job{
    width: 70%;
  }
  
  .open-positions .job h3 {
    color: rgba(0, 0, 255, 0.5);
    font-size: 1.2rem;
    /* margin-bottom: 10px; */
  }
  
  .open-positions .job p {
    font-size: 16px;
    /* line-height: 1.5; */
    margin-bottom: 10px;
  }
  
  .open-positions .job button {
    background-color: #007bff;
    color: #fff;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .open-positions .job button:hover {
    background-color: #0056b3;
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .hero h1 {
      font-size: 36px;
    }
  
    .hero p {
      font-size: 16px;
    }
  
    .scroll-down-btn {
      font-size: 16px;
    }
  
    .CareerCard {
      flex-wrap: wrap;
      text-align: center;
    }

    .CareerIcon{
      font-size:3rem;
    }
  
    .CareerCard .icon {
      /* flex: 0 0 50px; */
      
    }
  
    .about-section {
      padding: 50px 0;
    }
  }
  
  @media screen and (max-width:428px) {
    .CareerCard{
      width: 90%;
    }
    .about-section h1{
      margin-bottom: 0px;
      padding: 0px 5px;
    }
  }