.HeaderWrap{
    width:100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.8);
    position: fixed;
    top: 0;
    border-bottom: 1px solid lightgray;
    z-index: 11;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.Header{
    width: 98%;
    height: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
}

.LogoWrap{
    display: flex;
    justify-content: center;
    align-items: center;
    width:10%;
    height: auto;
}

.LogoWrap img{
    width: 100%;
    height: auto;
}

.MenuWrap{
    width: 70%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    /* background-color: red; */
}

.MenuWrap a, .NavP{
    text-decoration: none;
    padding: 3px 10px;
    border-radius: 10px 10px 10px 10px ;
    cursor: pointer;
    transform: scale(1);
    transition: 350ms;
    font-weight: 400;
    font-size: 0.9rem;
   
}

.Menu, .NavP{
    color: rgb(0, 0, 150);
    /* color: red; */
}

.MenuWrapActive{
    background-color:rgba(0, 0, 255, 0.5);
    color: white;
}

.MenuWrap a:hover, .NavP:hover{
    transform: scale(1.1);
}

.MenuWrap a:active, .NavP:active{
    transform: scale(0.9);
}

.BurgerWrap{
    display: none;
    justify-content: center;
    align-items: center;
    width: 10%;
    height: auto;
    cursor: pointer;
    transform: scale(1);
    transition: 350ms;
}

.BurgerIcon{
    font-size: 1.3rem;
    color: rgba(0, 0, 255, 0.8);
}

.BurgerWrap:hover{
    transform: scale(1.15);
}

.BurgerWrap:active{
    transform: scale(0.9);
}

@media screen and (max-width:884px) {
    .Header{
        padding: 20px;
    }
    .MenuWrap{
        width: 90%;
    }
}


@media screen and (max-width:834px) {
    .MenuWrap{
        display: none;
    }   
    .BurgerWrap{
        display: flex;
    }
    .MobileMenuWrap{
        display: flex;
        justify-content: space-around;
        flex-direction: column;
        align-items: center;
        width: 50%;
        height: auto;
        position: absolute;
        top:0;
        right:0;
        background-color: rgba(255, 255, 255, 0.8);
        z-index: 9999 !important;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px ;
        padding: 0px 10px;
    }
    .MobileMenuWrap a, .NavP{
        text-decoration: none;
        padding: 3px 10px;
        border-radius: 10px 10px 10px 10px ;
        cursor: pointer;
        transform: scale(1);
        transition: 350ms;
        font-weight: 500;
        font-size: 1rem;
        margin: 20px;
        
       
    }
    
    .MobileMenuWrapActive{
        background-color:rgba(0, 0, 255, 0.5);
        color: white;
    }
    
    .MobileMenuWrap a:hover, .NavP:hover{
        transform: scale(1.1);
    }
    
    .MobileMenuWrap a:active, .NavP:active{
        transform: scale(0.9);
    }

    .MobileAboutOptions{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        /* gap:5px; */
        margin-left: 60px;
    }

    .MobileAboutOptions p{
        padding: 5px;
        font-size: 0.9rem;
        color: rgb(0, 0, 150);
    }
    .MobileAboutOptions p:hover{
        /* padding: 5px; */
    }
}




/* @media screen and (max-width:768px) {
    .MenuWrap{
        display: none;
    }   
    .BurgerWrap{
        display: flex;
    }
    .MobileMenuWrap{
        display: flex;
        justify-content: space-around;
        flex-direction: column;
        align-items: center;
        width: 50%;
        height: auto;
        position: absolute;
        top:0;
        right:0;
        background-color: rgba(255, 255, 255, 0.8);
        z-index: 9999 !important;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px ;
        padding: 0px 10px;
    }
    .MobileMenuWrap a{
        text-decoration: none;
        padding: 3px 10px;
        border-radius: 10px 10px 10px 10px ;
        cursor: pointer;
        transform: scale(1);
        transition: 350ms;
        font-weight: 500;
        font-size: 1rem;
        margin: 20px;
        
       
    }
    
    .MobileMenuWrapActive{
        background-color:rgba(0, 0, 255, 0.5);
        color: white;
    }
    
    .MobileMenuWrap a:hover{
        transform: scale(1.1);
    }
    
    .MobileMenuWrap a:active{
        transform: scale(0.9);
    }

    .MobileAboutOptions{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap:5px;
        margin-left: 60px;
    }

    .MobileAboutOptions p{
        padding: 10px;
        color: rgb(0, 0, 150);
    }
    .MobileAboutOptions p:hover{
        padding: 10px;
    }
} */

@media screen and (max-width:428px) {
    .MobileMenuWrap{
        width: 70%;
    }
    .MobileMenuWrap a{
        margin: 15px;
       }
       .LogoWrap{
        width: 15%;
       }
 }

 @media screen and (max-width:390px) {
    .MobileMenuWrap{
        overflow-y: scroll;
        /* max-height: 100vh; */
    }
 }

@media screen and (max-width:360px) {
   .Header{
    padding: 10px;
   }
   .MobileMenuWrap a, .NavP{
    margin: 10px;
   }
}

@media screen and (max-width:320px) {
    .MobileMenuWrap a, .NavP{
     margin: 5px;
    }
    .MobileAboutOptions p{
        padding: 5px;
        color: rgb(0, 0, 150);
    }
 }