 .AboutUsDropDownMenu{
    width: 200px;
    height: 270px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    position: absolute;
    top:35px;
    left:35px;
    background-color: rgba(255, 255, 255, 0.9);
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px ;
    opacity: 0;
  transition: opacity 5s ease-in-out;

}

.AboutUsDropDownMenu.active {
    opacity: 1; /* Make it visible */
  }


/* .AboutUsDropDownMenu {
    position: relative;
    width: 200px;
    height: 300px;
    display: flex;
    top:0;
    left:100px;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    background-color: rgba(255, 255, 255, 0.9);
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
  
  .AboutUsDropDownMenu::before {
    content: '';
    position: absolute;
    top: -10px; 
    left: 50%; 
    transform: translateX(-50%); 
    border-style: solid;
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent rgba(255, 255, 255, 0.9) transparent; 
  } */
  
  

.AboutUsDropDownMenu p {
    cursor: pointer;
    transform: scale(1);
    transition: 350ms;
    width: 100%;
    height: 20%;
    text-align: center;
    padding-top: 15px;
    font-size: 0.9rem;
    color: rgb(0, 0, 150);
    /* color: purple; */
    /* color: rgba(0, 0, 255, 0.8); */
    /* padding: 10% 100%; */
}

.AboutUsDropDownMenu p:hover {
    /* transform: scale(1.1); */
    color: white;
    background-color: rgba(0, 0, 255, 0.3);
}

.AboutUsDropDownMenu p:active {
    /* transform: scale(0.9); */
}