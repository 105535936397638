.footer {
    background-color:rgba(0, 0, 255, 0.5);
    color: #ffffff; 
    padding: 30px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }
  
  .footer__container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;
  }
  
  .footer__logo img {
    height: 50px;
    
  }
  
  .footer__logo h1 {
    font-size: 18px;
    /* margin-right: 100px; */
    /* width: 250px; */
  }

  .footer__contact{
    display: none;
  }
  
  .footer__contact p, .footer__social p {
    margin-bottom: 5px;
    font-size: 1.2rem;
  
  }
  
  .footer__contact ul, .footer__social ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .footer__contact li, .footer__social li {
    margin-bottom: 5px;
    margin-right: 50px;
    /* width: 300px; */
  
  }
  
  .footer__social i {
    font-size: 20px;
    margin-right: 10px;
  }


  .FbIcon, .InstagramIcon, .TwitterIcon{
    color:white;
    font-size: 1.5rem;
  }

  


/* Desktop Contact */
.FooterDesktopContact{
  display: flex;
  width: 100%;
  height: auto;
  justify-content: space-between;
  align-items: flex-start;
  /* margin:100px; */
  margin-top: 100px;
  /* background-color: red; */
  /* margin-bottom: 100px; */
}

@media screen and (max-width:884px) {
    .FooterDesktopContact{
      /* width: 105%; */
    }
}

  
  @media screen and (max-width: 768px) {
    .footer__container {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .footer__logo img {
    
    }
  
    .footer__contact, .footer__social {
      margin-top: 30px;
    }
    .footer__contact{
      display: block;
    }
    .FooterDesktopContact{
      display: none;
    }
  }
  